// routes
import { PATH_DASHBOARD } from "src/routes/paths";

// icons
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import CorporateFareRoundedIcon from "@mui/icons-material/CorporateFareRounded";
import AssessmentIcon from "@mui/icons-material/Assessment";
// import GoogleIcon from "@mui/icons-material/Google";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
// import CurrencyRupeeRoundedIcon from "@mui/icons-material/CurrencyRupeeRounded";
// import BusinessRoundedIcon from "@mui/icons-material/BusinessRounded";
// import SupportAgentRoundedIcon from "@mui/icons-material/SupportAgentRounded";
// import CategoryRoundedIcon from "@mui/icons-material/CategoryRounded";

// components
// import SvgIconStyle from "../../../components/SvgIconStyle";

// ----------------------------------------------------------------------

// const getIcon = (name) => (
//   <SvgIconStyle
//     src={`/assets/icons/navbar/${name}.svg`}
//     sx={{ width: 1, height: 1 }}
//   />
// );

// const ICONS = {
//   user: getIcon("ic_user"),
//   ecommerce: getIcon("ic_ecommerce"),
//   analytics: getIcon("ic_analytics"),
//   dashboard: getIcon("ic_dashboard"),
// };

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    // subheader: 'general v3.4.0',
    items: [
      { title: "Home", path: "/dashboard/home", icon: <HomeRoundedIcon /> },
      // {
      //   title: "User Management",
      //   path: "/dashboard/users",
      //   icon: ICONS.user,
      //   children: [
      //     { title: "Users List", path: "/dashboard/users/users-list" },
      //     { title: "Create User", path: "/dashboard/users/create-user" },
      //   ],
      // },
      {
        title: "Reseller",
        path: "/dashboard/reseller",
        icon: <CorporateFareRoundedIcon />,
      },
      {
        title: "Organisation",
        path: "/dashboard/organisation",
        icon: <CorporateFareRoundedIcon />,
      },
      {
        title: "Reports",
        path: "/dashboard/reports",
        icon: <AssessmentIcon />,
      },
      {
        title: "Whatsapp",
        path: "/dashboard/whatsapp",
        icon: <WhatsAppIcon />,
        children: [
          {
            path: "/dashboard/whatsapp/wallet",
            title: "Wallet",
            children: [
              {
                title: "Partner Rates / PNB",
                path: PATH_DASHBOARD.whatsapp.partnerRatesPnb,
              },
              {
                title: "Transactions",
                path: PATH_DASHBOARD.whatsapp.transactions,
              },
            ],
          },
        ],
      },
      // {
      //   title: "Google Workspace",
      //   path: "/dashboard/google-workspace",
      //   icon: <GoogleIcon />,

      // },
      // {
      //   title: "Invoices",
      //   path: "/dashboard/invoice",
      //   icon: <CurrencyRupeeRoundedIcon />,
      // },
      // {
      //   title: "Company Profile",
      //   path: PATH_DASHBOARD.company.demoEdit,
      //   icon: <BusinessRoundedIcon />,
      // },
      // {
      //   title: "Products",
      //   path: "/dashboard/products",
      //   icon: <CategoryRoundedIcon />,
      // children: [
      //   { title: "CRM", path: "/dashboard/products/crm" },
      //   { title: "Chat", path: "/dashboard/products/chat" },
      //   { title: "Social", path: "/dashboard/products/social" },
      // ],
      // },
      // {
      //   title: "Support",
      //   path: "/dashboard/support",
      //   icon: <SupportAgentRoundedIcon />,
      // },
    ],
  },
  // {
  //   subheader: 'management',
  //   items: [
  //     {
  //       title: 'user',
  //       path: '/dashboard/user',
  //       children: [
  //         { title: 'Four', path: '/dashboard/user/four'
  //         children: [
  //         { title: 'Five', path: '/dashboard/user/five' },
  //         { title: 'Six', path: '/dashboard/user/six' },
  //       ],
  //     },
  //   ],
  //   ],
  // },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
  //     {
  //       title: 'user',
  //       path: '/dashboard/user',
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'Four', path: '/dashboard/user/four' },
  //         { title: 'Five', path: '/dashboard/user/five' },
  //         { title: 'Six', path: '/dashboard/user/six' },
  //       ],
  //     },
  //   ],
  // },
];

export default navConfig;
