import { createContext, useReducer } from "react";
import { Outlet } from "react-router";
import getPartnersPnb from "src/services/whatsapp/wallet/getPartnersPnb";
import getTransactions from "src/services/whatsapp/wallet/getTransactions";

// Initial State
const initialState = {
  partnersPnb: null,
  transactions: null,
  loader: true,
};

// Action handlers
const handlers = {
  INITIALIZE_PARTNER_RATES_PNB: (state, action) => {
    const { partners } = action.payload;
    return { ...state, partnersPnb: partners, loader: false };
  },
  INITIALIZE_TRANSACTIONS: (state, action) => {
    const { response } = action.payload;
    return { ...state, transactions: response, loader: false };
  },
};

// Create reducer
const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

// Create Context
export const WhatsAppWalletContext = createContext();

// Provider Component
export const WhatsAppWalletProvider = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initializePartnerRatesPnb = async () => {
    try {
      const response = await getPartnersPnb();
      const { partners } = response || {};

      dispatch({
        type: "INITIALIZE_PARTNER_RATES_PNB",
        payload: {
          partners,
        },
      });
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  };

  const initializeTransactions = async () => {
    try {
      const response = await getTransactions();

      dispatch({
        type: "INITIALIZE_TRANSACTIONS",
        payload: {
          response,
        },
      });
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  };

 
  // Expose functions to be called externally
  const exposedFunctions = {
    initializePartnerRatesPnb,
    initializeTransactions,
  };

  return (
    <WhatsAppWalletContext.Provider
      value={{
        partnersPnb: state.partnersPnb,
        transactions: state.transactions,
        loader: state.loader,
        ...exposedFunctions,
      }}
    >
      <Outlet />
    </WhatsAppWalletContext.Provider>
  );
};
