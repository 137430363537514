import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
// config
import { PATH_AFTER_LOGIN } from "../config";
// components
import LoadingScreen from "../components/LoadingScreen";
// context provider
import { OrgListProvider } from "../contexts/OrgListContext";
import { ResellerProvider } from "../contexts/ResellerContext";
import { ReportsProvider } from "src/contexts/ReportsContext";
import { GoogleWorkspaceProvider } from "src/contexts/GoogleWorkspaceContext";
import { WhatsAppWalletProvider } from "src/contexts/whatsApp/wallet/WhatsAppWalletContext";
import PartnerRatesPnb from "src/pages/whatsApp/wallet/PartnerRatesPnb";
import Transactions from "src/pages/whatsApp/wallet/Transactions";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};
export default function Router() {
  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: "login-unprotected", element: <Login /> },
      ],
    },
    {
      path: "/",
      element: <Navigate to="/dashboard/home" replace />,
    },
    {
      path: "/dashboard",
      element: (
        <AuthGuard>
          <OrgListProvider>
            <ResellerProvider>
              <DashboardLayout />
            </ResellerProvider>
          </OrgListProvider>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "home", element: <Home /> },
        {
          path: "organisation",
          children: [
            {
              element: <Organisation />,
              index: true,
            },
            { path: "new", element: <NewOrg /> },
            { path: ":name/edit", element: <NewOrg /> },
            { path: "crm-mapping", element: <CrmMapping /> },
          ],
        },
        {
          path: "reseller",
          children: [
            {
              element: <Reseller />,
              index: true,
            },
            { path: "new", element: <NewReseller /> },
            { path: ":name/edit", element: <NewReseller /> },
          ],
        },
        {
          path: "invoice",
          children: [
            {
              element: <Navigate to="/dashboard/invoice/list" replace />,
              index: true,
            },
            { path: "list", element: <InvoiceList /> },
            { path: ":id", element: <InvoiceDetails /> },
          ],
        },
        { path: "company/:name/edit", element: <CompanyProfile /> },
        {
          path: "users",
          children: [
            {
              element: <Navigate to="/dashboard/users/users-list" replace />,
              index: true,
            },
            { path: "users-list", element: <UserList /> },
            { path: "create-user", element: <UserCreate /> },
            { path: ":name/edit", element: <UserCreate /> },
          ],
        },
        {
          path: "reports",
          element: <ReportsProvider />,
          children: [
            {
              element: <Reports />,
              index: true,
            },
            { path: "new", element: <NewEditReport /> },
            { path: ":name/:id/edit", element: <NewEditReport /> },
          ],
        },
        {
          path: "whatsapp",
          children: [
            {
              path: "wallet",
              element: <WhatsAppWalletProvider />,
              children: [
                {
                  path: "partner-rates-pnb",
                  element: <PartnerRatesPnb/>,
                },
                {
                  path: "transactions",
                  element: <Transactions />,
                },
              ],
            },
          ],
        },
        // {
        //   path: "google-workspace",
        //   element: <OrgListProvider />,
        //   children: [
        //     {
        //       element: <GoogleWorkspaceProvider />,
        //       children: [
        //         {
        //           element: <GoogleWorkspace />,
        //           index: true,
        //         },
        //       ],
        //     },
        //   ],
        // },
        {
          path: "google-workspace",
          element: <GoogleWorkspaceProvider />,
          children: [
            { element: <GoogleWorkspace />, index: true },
            { path: "info", element: <CustomerInfo /> },
          ],
        },
      ],
    },
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import("../pages/auth/Login")));

// Dashboard
const Home = Loadable(lazy(() => import("../pages/Home")));
const InvoiceList = Loadable(lazy(() => import("../pages/InvoiceList")));
const InvoiceDetails = Loadable(lazy(() => import("../pages/InvoiceDetails")));
const CompanyProfile = Loadable(lazy(() => import("../pages/CompanyEdit")));
const Reseller = Loadable(lazy(() => import("../pages/Reseller")));
const NewReseller = Loadable(lazy(() => import("../pages/NewReseller")));
const Organisation = Loadable(lazy(() => import("../pages/Organisation")));
const NewOrg = Loadable(lazy(() => import("../pages/NewOrg")));
const CrmMapping = Loadable(lazy(() => import("../pages/CrmMapping")));
const UserList = Loadable(lazy(() => import("../pages/UserList")));
const UserCreate = Loadable(lazy(() => import("../pages/UserCreate")));
const Reports = Loadable(lazy(() => import("../pages/Reports")));
const GoogleWorkspace = Loadable(
  lazy(() => import("../pages/GoogleWorkspace"))
);
const CustomerInfo = Loadable(
  lazy(() => import("../pages/WorkspaceCustomerInfo"))
);
const NewEditReport = Loadable(lazy(() => import("../pages/NewEditReport")));
const NotFound = Loadable(lazy(() => import("../pages/Page404")));
