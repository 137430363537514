import { useEffect, useMemo, useState } from "react";
// @mui
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
// Api's
import { useSnackbar } from "notistack";
import setRates from "src/services/whatsapp/wallet/setRates";

// ----------------------------------------------------------------------

const EditableCell = ({ value, onChange, viewOnly }) => (
  <TextField
    value={value}
    onChange={onChange}
    fullWidth
    disabled={viewOnly}
    placeholder="Ex: 0.72=72 Paisa"
  />
);

// ----------------------------------------------------------------------

const RatesDialog = ({
  rates,
  type,
  typeId,
  viewOnly,
  isLoading,
  openRateDialog,
  handleCloseRateDialog,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const initialRates = useMemo(
    () => [
      { name: "Market", value: "India", editable: false },
      {
        name: "Marketing",
        value: (rates && rates.marketing) || "",
        editable: true,
      },
      {
        name: "Utility",
        value: (rates && rates.utility) || "",
        editable: true,
      },
      {
        name: "Authentication",
        value: (rates && rates.authentication) || "",
        editable: true,
      },
      {
        name: "Service",
        value: (rates && rates.service) || "",
        editable: true,
      },
    ],
    [rates]
  );

  const [editableRates, setEditableRates] = useState(initialRates);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCellChange = (index, newValue) => {
    const updatedRates = editableRates.map((rate, i) =>
      i === index ? { ...rate, value: newValue } : rate
    );
    setEditableRates(updatedRates);
  };

  const renderTableHead = () => (
    <TableRow>
      {editableRates.map((rate) => (
        <TableCell key={rate.name}>{rate.name}</TableCell>
      ))}
    </TableRow>
  );

  const renderTableBody = () => (
    <TableRow>
      {editableRates.map((rate, index) => (
        <TableCell key={rate.name}>
          {rate.editable ? (
            <EditableCell
              value={rate.value}
              onChange={(e) => handleCellChange(index, e.target.value)}
              viewOnly={viewOnly}
            />
          ) : (
            <Typography>{rate.value}</Typography>
          )}
        </TableCell>
      ))}
    </TableRow>
  );

  const handleRatesUpdate = async () => {
    try {
      setIsSubmitting(true);
      // Initial rates object structure
      const initialRatesObject = {
        type: type,
        typeId: typeId,
        marketing: "",
        utility: "",
        authentication: "",
        service: "",
      };

      // Converting editableRatesArray to the desired object format
      const convertedRatesObject = editableRates.reduce((acc, rate) => {
        if (rate.name !== "Market") {
          acc[rate.name.toLowerCase()] = rate.value.toString();
        }
        return acc;
      }, initialRatesObject); // Using initialRatesObject as the initial accumulator

      const data = await setRates(convertedRatesObject);
      console.log(data);
      enqueueSnackbar("Rates updated successfully");
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Rates update failed", { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    setEditableRates(initialRates);
  }, [initialRates]);

  return (
    <Dialog
      open={openRateDialog}
      onClose={handleCloseRateDialog}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle sx={{ mb: 1 }}>Rates</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Stack
            sx={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Stack>
        ) : (
          <Table>
            <TableHead>{renderTableHead()}</TableHead>
            <TableBody>{renderTableBody()}</TableBody>
          </Table>
        )}
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent={rates === null ? "space-between" : "flex-end"}
          sx={{ width: "100%" }}
        >
          {rates === null && <Alert severity="warning">No Rates Set</Alert>}
          <Stack direction="row" spacing={1}>
            <Button variant="outlined" onClick={handleCloseRateDialog}>
              Close
            </Button>
            {!viewOnly && (
              <LoadingButton
                startIcon={<CloudUploadIcon />}
                variant="contained"
                loading={isSubmitting}
                onClick={handleRatesUpdate}
              >
                Save
              </LoadingButton>
            )}
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default RatesDialog;
