// react
import { createContext, useEffect, useReducer } from "react";
import { Outlet } from "react-router";
import assignOrg from "src/services/googleWorkspace/assignOrg";
// utils
import axios from "src/utils/axios";
import { isValidToken } from "src/utils/jwt";
// Initial State
const initialState = {
  login_url: null,
  accounts: [],
  customers: [],
};

// Action handlers
const handlers = {
  INITIALIZE: (state, action) => {
    const { login_url, accounts, customers } = action.payload;
    return {
      ...state,
      login_url,
      accounts,
      customers,
    };
  },
};

// Create reducer
const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

// Create Context
export const GoogleWorkspaceContext = createContext();

// Provider Component
export const GoogleWorkspaceProvider = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const initialize = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const controller = new AbortController();
      if (accessToken && isValidToken(accessToken)) {
        const response = await axios({
          method: "get",
          url: `/api/google-workspace/account_setup`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const {
          login_url,
          accounts,
          customers = accounts[0]?.customers,
        } = await response.data;
        // cancel the request
        controller.abort();
        dispatch({
          type: "INITIALIZE",
          payload: {
            login_url,
            accounts,
            customers,
          },
        });
      } else {
        dispatch({
          type: "INITIALIZE",
          payload: {
            login_url: null,
            accounts: [],
            customers: [],
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: "INITIALIZE",
        payload: {
          login_url: null,
          accounts: [],
          customers: [],
        },
      });
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const setupGoogleWorkspaceAccount = async (setupData) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      await axios({
        method: "post",
        url: `/api/google-workspace/account_setup`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: setupData,
      });
      await initialize();
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const assignOrganisation = async (customerId, orgId) => {
    try {
      await assignOrg(customerId, orgId);
      await initialize();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <GoogleWorkspaceContext.Provider
      value={{
        accounts: state.accounts,
        loginUrl: state.login_url,
        customers: state.customers,
        setupGoogleWorkspaceAccount,
        assignOrganisation,
      }}
    >
      <Outlet />
    </GoogleWorkspaceContext.Provider>
  );
};
